'use client';

import type { SxProps, Theme } from '@mui/material/styles';
import type * as React from 'react';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

interface Props {
  height?: number;
  width?: number;
  component?: React.ElementType;
  sx?: SxProps<Theme>;
}

const Spacer = ({ height = 1, width = 0, component = 'div', sx }: Props) => {
  const theme = useTheme();

  return <Box component={component} sx={{ height: theme.spacing(height), width: theme.spacing(width), ...sx }} />;
};

export default Spacer;
