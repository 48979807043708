'use client';

import type * as React from 'react';

import DirectionsRoundedIcon from '@mui/icons-material/DirectionsRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useImmer } from 'use-immer';

import { useI18n } from '@pegase/i18n/client';

import { api } from '~/shared/trpc/react';

interface State {
  email: string;
  error: string | null;
  openSnackBar: boolean;
}

export const Subscribe = () => {
  const t = useI18n();
  const [state, updateState] = useImmer<State>({
    email: '',
    error: null,
    openSnackBar: false,
  });

  const mutation = api.subscribe.save.useMutation({
    onSuccess: () => {
      updateState((draft) => {
        draft.openSnackBar = true;
        draft.error = null;
        draft.email = '';
      });
    },
    onError: (errors) => {
      console.log('errors.message', errors);

      let errorMessage = '';
      if (errors.data?.code === 'BAD_REQUEST') {
        errorMessage = t('validation.invalid_email');
      } else if (errors.message.includes('SQLITE_CONSTRAINT')) {
        errorMessage = t('subscribe.already_subscribed');
      } else {
        errorMessage = t('common.internal_error_ocurred');
      }

      updateState((draft) => {
        draft.error = errorMessage;
      });
    },
  });

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateState((draft) => {
      draft.email = event.target.value;
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutation.mutate({ email: state.email });
  };

  const handleCloseSnackBar = () => {
    updateState((draft) => {
      draft.openSnackBar = false;
    });
  };

  return (
    <Container disableGutters component="section" sx={{ pt: 4, pb: 4, pl: { xs: 1, md: 0 }, pr: { xs: 1, md: 0 } }}>
      <Stack
        justifyContent="center"
        spacing={2}
        sx={{
          px: 4,
          py: 6,
          borderRadius: 2,
          mx: 'auto',
        }}>
        <Typography component="h6" variant="h4" fontWeight={700} textAlign="center" gutterBottom>
          {t('subscribe.headline_1')}
        </Typography>
        <Typography variant="h6" fontWeight={500} textAlign="center" gutterBottom>
          {t('subscribe.headline_2')}
        </Typography>
        <form onSubmit={handleSubmit} id="demo">
          <Box textAlign="center">
            <FormControl error={!!state.error}>
              <OutlinedInput
                sx={{ m: 1, width: '30ch' }}
                placeholder={t('common.enter_email')}
                type="email"
                required
                value={state.email}
                onChange={handleEmailChange}
                startAdornment={
                  <InputAdornment position="start">
                    <EmailRoundedIcon color="primary" />
                  </InputAdornment>
                }
                endAdornment={
                  <>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton
                      color="primary"
                      disabled={mutation.isPending}
                      type="submit"
                      sx={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        height: '55px',
                      }}>
                      <DirectionsRoundedIcon />
                    </IconButton>
                  </>
                }
              />
              <FormHelperText sx={{ fontWeight: 500 }}>{state.error ?? ' '}</FormHelperText>
            </FormControl>
          </Box>
        </form>
      </Stack>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        open={state.openSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
          {t('subscribe.success')}
        </Alert>
      </Snackbar>
    </Container>
  );
};
